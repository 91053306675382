import React from "react";
import {Navigate ,useLocation , Route} from "react-router-dom";
import {getSession} from "../Auth/AuthHandler";


export default function PrivateRoute({ children }) {
    const location = useLocation();
    const session = getSession(); // your auth logic
  
    if (!session) {
      return (
        <Navigate
          to={`/login?url=${encodeURIComponent(location.pathname + location.search)}`}
          replace
          state={{ from: location }}
        />
      );
    }
  
    return children;
  }